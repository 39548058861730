/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Frontend WEBARQ
==============================================================================================*/
@import "sass-lib";

body {  
    color: #333;
    font-size: 14px;
    font-family: 'Source Sans Pro';
    position: relative;
    overflow-x: hidden;
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }

/* structure 
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.flex-list {display: -webkit-flex;display: flex;display: -moz-box;display: -ms-flexbox;-webkit-flex-flow: row wrap;}

section { position: relative; }
.wrapper {width: 1170px;margin: 0 auto;}



/* general
----------------------------------------------------------------------------------------------*/
.lf {float: left;}
.rh {float: right;}
.flag {
    display: inline-block; width: 12px; height: 18px; position: relative; background: #333;
    &.yl {background: #FFD93D;}
    &.prpl {background: #9141AE;}
    &.pink {background: #EE4266;}
    &.dgrey {background: #3B454B;}
    &.grey {background: #83919A;}
    &.lgrn {background: #50C5B7;}
    &.black {background: #000000;}
    &:after {
        content: ""; width: 0; height: 0; border-bottom: 4px solid #fff; border-left: 6px solid transparent;border-right: 6px solid transparent; position: absolute; bottom: 0; left: 0;
    }
}

.btn {
    position: relative;background: $c_pink; line-height: 48px; display: inline-block; color: #fff; text-transform: uppercase; font-size: 14px; font-weight: 600;@include transition(all ease-out 0.3s); padding: 0 24px; @include borderRadius(10px); @include boxSizing(border-box);text-align: center;
    &:hover {
        color: #fff;@include transition(all ease-out 0.3s);background: #d03455;
    }
    &.plus {
        padding-left: 50px; 
        &:after {
            content:""; width: 30px; height: 30px; background: url('../images/material/ico-plus.png') no-repeat; display: block; position: absolute; top: 0; bottom: 0; margin: auto; left: 13px;
        }
    }
    &.white {
        background: #f2f2f2; color: $c_pink;
    }
    &.dgreen {
        background: $c_lgrn;
    }
    &.yl {
        background: $c_yl; color: #111;
    }
}

.btn-ico {
    padding: 0 15px 0 45px; line-height: 40px; display: inline-block; background: #ccc; @include boxShadow(0 2px 10px -2px rgba(0,0,0,0.3)); @include borderRadius(8px); font-size: 16px; font-weight: 700; color: #000; margin-left: 10px;@include transition(all ease-out 0.3s);
    &.unduh {
        background: #435058 url('../images/material/ic-unduh.png') no-repeat left 14px center; color: #fff;
        &:hover {background-color: #7b93a2;}
    }
    &.print {
        background: #FFD93D url('../images/material/ic-print.png') no-repeat left 14px center;
        &:hover {background-color: #efc723;}
    }
    &.approve {
        background: #73D8CC url('../images/material/ic-approve.png') no-repeat left 14px center;
        &:hover {background-color: #17dcc5;}
    }
    &:hover {@include transition(all ease-out 0.3s);}
}

.small-btn {
    border: 1px solid #d8d8d8; color: #83919A; font-size: 14px; display: inline-block; @include borderRadius(6px); line-height: 28px; padding: 0 15px;
    margin-right: 10px; @include transition(all ease-out 0.25s);
    &:hover {
        @include transition(all ease-out 0.25s); background: #83919A; color: #fff; border-color: #83919A;
    }
}
.btn-down {display: inline-block; margin-right: 10px; padding: 0 30px; line-height: 44px; background: $c_yl; @include borderRadius(8px); font-size: 14px; color: #000; font-weight: 700;}

/* std-content
----------------------------------------------------------------------------------------------*/
.std-content{
    ul {
        margin: 0 0 30px 0;
        li {
            list-style: disc; margin: 0 0 0 20px; padding: 0;font-weight: normal;font-style: normal;font-stretch: normal;line-height: 1.3;letter-spacing: normal;text-align: left;
        }  
    }
    ol { 
        margin: 0 0 30px 0;
        li {
            list-style: decimal; margin: 0 0 0 20px; padding: 0;font-weight: normal;font-style: normal;font-stretch: normal;line-height: 1.3;letter-spacing: normal;text-align: left;
        }  
    }
    table,thead,tbody,tfoot,tr,th,td{
        border: 1px solid grey;
    }
    table{
        th,td{padding: 10px;line-height: 1.3;}
        th{font-weight: bold;}
    }
}


/* header
----------------------------------------------------------------------------------------------*/
header {
    padding: 45px 40px; position: fixed; top: 0px; left: 0; bottom: 0; width: 100px; @include boxSizing(border-box); z-index: 9; padding-right: 10px; @include boxShadow(0 -7px 10px 0 rgba(0,0,0,0.075));
    &:before {content:""; left: 0; right: 0; position: fixed; height: 4px; background: #000; display: block; top: 0;}
    .mnu-asside {
        .logo {
            margin-bottom: 70px; display: block; width: 40px; overflow: hidden; margin-left: -8px;
            img {width: 188px; max-width: none;}
        }
        h5 {text-transform: uppercase; font-size: 14px; color: #435058; margin-bottom: 15px; font-weight: 700; margin-left: -7px;}
        nav {
            ul {
                li {
                    a {
                        display: block; color: $c_gnrl; font-size: 0px; line-height: 24px; margin-left: -40px; padding: 13px 10px 10px 75px; @include transition(all ease-out 0.3s); position: relative; @include borderRadius(0 5px 5px 0);
                        i {
                            display: inline-block; background-position: top center !important; width: 26px; height: 26px; background-size: 100% !important; vertical-align: middle;
                            position: absolute; left: 40px;
                        }
                        &:before {
                            @include transition(all ease-out 0.3s); content: ""; width: 0px; background: $c_yl; top: 0; bottom: 0; position: absolute; left: 0; display: block;
                        }
                        &:hover,&.active {
                            @include boxShadow(-1px 0px 10px 0px rgba(0,0,0,0.1));
                            &:before {width: 5px;@include transition(all ease-out 0.3s);}
                            i {background-position: bottom center !important;}
                        }
                    }
                }
            }
        }
        .mn-akun {
            margin-top: 35px;
        }
    }
    &.mnu-text {
        width: 300px; padding-right: 40px;
        .mnu-asside {
            .logo {width: auto; margin-left: 0;}
            h5 {margin-left: 0;}
            nav {
                ul {
                    li {
                        a {font-size: 14px; padding-right: 25px;}
                    }
                }
            }
        }
        + .middle-content {
            padding-left: 300px;
        }
        .wrp-head {
            padding: 0 40px; margin: 0 -40px;
            .mCSB_scrollTools {right: 6px;}
            .mCustomScrollBox {@include boxSizing(border-box); max-width: none; padding: 0 40px; margin: 0 -40px;}
            .mCSB_inside > .mCSB_container {@include boxSizing(border-box); max-width: none; padding: 0 40px; margin: 0 -40px;}
        }
    }
    .wrp-head {
        padding: 0 20px 0 40px; margin: 0 -20px 0 -40px; height: calc(100vh - 190px); position: relative;
        .mCSB_scrollTools {right: 4px;}
        .mCustomScrollBox {@include boxSizing(border-box); max-width: none; padding: 0 20px 0 40px; margin: 0 -20px 0 -40px;}
        .mCSB_inside > .mCSB_container {@include boxSizing(border-box); max-width: none; padding: 0 20px 0 40px; margin: 0 -20px 0 -40px;}

        
    }
}

/* middle
----------------------------------------------------------------------------------------------*/
.login {
    position: relative;
    .wrp-login {height: 100vh; background-size: cover !important;}
    .wrapper {
        width: 1300px; @include transform(translateY(-50%)); top: 50%; position: relative;
    }
    .txt {
        width: 610px; position: absolute; bottom: 30px; color: #D8D8D8;
        h3 {font-size: 40px; font-weight: 700; line-height: 50px; margin-bottom: 12px;}
        p {color: #D8D8D8; font-size: 24px; font-weight: 300; margin-bottom: 0;}
    }
    .form-std {
        width: 540px; background: #000; @include boxSizing(border-box); padding: 65px; @include borderRadius(12px); color: #606060; font-size: 15px;
        >img {display: block; width: 172px; margin: 0 auto 30px;}
        p {
             font-size: 15px; margin-bottom: 30px; font-weight: 600;
            a {
                color: #606060;
                &:hover {color: #c4c4c4;text-decoration: underline;}
            }
            &.site {margin-bottom: 0;text-align: center; margin-top: 35px;}
        }
        /*form {
            .row {
                margin-bottom: 30px;
                label {color: #606060; font-size: 15px;}
                .btn {margin-top: 10px;}
                input[type="text"],input[type="email"],input[type="password"] {background: #121211; border-color: #121211;color: #C4C4C4;}
                @include placeholder {color: #C4C4C4;}
            }
        }*/
        .btm-log {margin-top: 20px; @extend .after_clear;}
        .ck_box {
            float: left;
            label {
                position: relative; padding-left: 32px; font-size: 14px; line-height: 20px; cursor: pointer;
                input {
                    width: 20px; height: 20px; position: absolute; left: 0; background: url('../images/material/ck_box2.png') no-repeat top center;
                    &:checked {background-position: bottom center;}
                }
                &:hover{
                    color: #C4C4C4;
                }
            }
        }
        .forgot {
            float: right; color: #606060; font-size: 15px; @include transition(all ease-out 0.25s);
            &:hover { @include transition(all ease-out 0.25s); color: #C4C4C4; text-decoration: underline;}
        }
    }
}

.middle-content {
    padding-left: 100px; background: #fcfcfc;
    .main-content {
        padding: 50px 90px;  height: 100%; position: relative;
        .bg-yllow {
            background: url('../images/material/bnnr-yllow.png') no-repeat 80% top; height: 517px; position: absolute; top: 0;left: 0; right: 0;
            @include borderRadius(0 0 0 40px); overflow: hidden; z-index: 1;
        }
        > *{
            position: relative; z-index: 3;
        }
        .top-content {
            position: relative; z-index: 6; @extend .after_clear; margin-bottom: 50px;
            .lf {
                h2 {font-size: 32px; color: $c_black; font-weight: 700; margin-bottom: 15px;}
                p {font-size: 16px; color: rgba(0,0,0,0.6); margin-bottom: 0;}
            }
            .rh {@extend .after_clear;}
            .notif {
                float: left; padding: 7px 0; margin-right: 45px; position: relative;
                .ic{
                    width: 18px; height: 18px; display: block; background: url('../images/material/notif-ico.png'); position: relative; cursor: pointer;
                    &.act {
                        &:after {
                           width: 10px; height: 10px; content: ""; background: $c_pink; display: block; border: 2px solid #fff; @include borderRadius(50%); right: -5px;
                           position: absolute; top: -5px;     
                        }
                    }
                }
                .drop-notif {
                    background: #1F2122; position: absolute; width: 470px; padding: 30px; @include borderRadius(15px); right: -172px; left: auto; @include boxSizing(border-box);z-index: 9; top: 155%; display: none; 
                    &:before {content:""; left: auto; right: 165px; width: 0; height: 0; border-left: 15px solid transparent; border-right: 15px solid transparent; border-bottom: 15px solid #1F2122; position: absolute; top: -12px;}
                    ul {
                        li {
                            padding-bottom: 15px; margin-bottom: 15px; border-bottom: 1px solid rgba(255,255,255,0.1);
                            h5 {
                                color: #9A9A9A; font-size: 16px; line-height: 24px;
                                b {
                                    color: #fff; font-weight: 600;
                                    span {color: #EE4266;}
                                }
                            }
                            &:last-child {border: none; padding-bottom: 0; margin-bottom: 0;}
                        }
                    }
                    .date {color: #fff; display: inline; text-align: right; display: block;}
                }
            }
            .user {
                float: left; position: relative;
                .ic_user {
                    h5 {
                        padding-left: 36px; position: relative; line-height: 30px; cursor: pointer; color: #656565; font-weight: 600; font-size: 14px; padding-right: 30px; background: url('../images/material/arr.png') no-repeat right center; overflow: hidden; max-width: 130px; white-space: nowrap;text-overflow: ellipsis;
                        i {width: 24px; height: 24px; position: absolute; border: 3px solid rgba(255,255,255,0.5);display: block;background-size: 100% !important; @include borderRadius(50%); overflow: hidden;left: 0;}
                    }
                }
                .drop-user {
                    background: #1F2122; position: absolute; width: 170px; padding: 10px 30px 20px; @include borderRadius(10px); right: 0; left: auto; @include boxSizing(border-box);z-index: 9; top: 162%; display: none;
                    &:before {content:""; left: 0; right: 0; width: 0; height: 0; border-left: 15px solid transparent; border-right: 15px solid transparent; border-bottom: 15px solid #1F2122; position: absolute; top: -12px; margin: auto;}
                    ul {
                        li {
                            a {
                                display: block; color: #BCC9D1; font-size: 14px; line-height: 24px; padding: 13px 10px 10px 40px; @include transition(all ease-out 0.3s); position: relative; @include borderRadius(0 5px 5px 0);
                                i {
                                    display: inline-block; background-position: top center !important; width: 26px; height: 26px; background-size: 100% !important; vertical-align: middle;
                                    position: absolute; left: 0px;
                                }
                                &:before {
                                    @include transition(all ease-out 0.3s); content: ""; background: $c_yl; top: auto; bottom: 0; position: absolute; left: 42px; display: block; right: auto;height: 2px; width: 0;
                                }
                                &:hover,&.active {
                                    color: $c_yl;@include transition(all ease-out 0.3s);
                                    &:before {width: 10%;@include transition(all ease-out 0.3s);}
                                }
                            }
                        }
                    }
                }
            }
        }
        .filter-wrp {
            @extend .after_clear;
            .src {float: left;}
            .filter {float: left; margin-left: 10px;}
            &.multi-btn {
                .lf {margin-bottom: 20px;}
            }
        }
        &.dashboard {
            .bg-yllow {
                height: 567px; background-size: cover;
            }
            .inner-wrap {
                padding: 0; background: transparent; @include boxShadow(none);
            }
        }
    }
    .map-dashboard {
        position: relative; @include borderRadius(20px 20px 0 0); overflow: hidden;
        h3 {position: absolute; top: 45px; left: 55px; color: #fff; line-height: 44px; font-size: 24px; font-weight: 700; z-index: 2; background: #63BDFF;}
        .filter-year {
            position: absolute; top: 45px; right: 65px; width: 140px; z-index: 2;
            select {background: rgba(43,43,43,0.4) url('../images/material/arr-white.png') no-repeat right 18px center; border: none; width: 100%; padding: 12px 60px 12px 20px; @include boxSizing(border-box); @include borderRadius(10px); font-size: 14px; color: #fff; line-height: 20px; border: 1px solid rgba(255,255,255,0.6);}
        }
        #map-location {height: 400px; background: #2B2B2B; z-index: 1;}
    }
    .src {
        position: relative;
        form {position: relative;}
        @include placeholder{color: #756E4F; font-weight: 400;}
        input[type="text"] {width: 278px; padding: 16px 20px; background: rgba(255,255,255,0.6); border: 1px solid #fff; @include borderRadius(10px); font-size: 14px; color: #756E4F; padding-left: 50px; font-weight: 400; @include boxSizing(border-box);}
        input[type="submit"] {width: 30px; height: 30px; background: url('../images/material/src-ico.png'); padding: 0; position: absolute; left: 12px; top: 0; bottom: 0; margin: auto; border: none; cursor: pointer;}
    }
    .ttgl-filt {
        padding: 9px 20px; font-size: 14px; padding-left: 50px; background: rgba(255,255,255,0.6) url('../images/material/filter-ico.png') no-repeat 10px center; line-height: 30px; display: block; @include borderRadius(10px);@include boxSizing(border-box)
        border: 1px solid #fff; color: $c_black; font-weight: 600; cursor: pointer;
    }
}


.main-content {
    table {
        width: 100%; background: #fff;@include borderRadius(10px); margin-top: 20px; @include borderRadius(10px);
        thead {
            background: $c_dgrey; color: #BBBDBE; text-transform: uppercase;
            th {
                padding: 15px 35px;font-size: 14px; font-weight: 600; text-align: left;
                &:nth-child(1) {@include borderRadius(10px 0 0 0); overflow: hidden; padding-left: 40px;}
                &:last-child {@include borderRadius(0 10px 0 0); overflow: hidden;}
            }
        }
        tbody {
            td {
                padding: 12px 35px; color: rgba(0,0,0,0.7); font-size: 14px;  border-bottom: 1px solid #f2f2f2; vertical-align: middle;
                a {color: #000; display: block;}
                h5 {padding-left: 30px; position: relative; font-size: 16px; font-weight: 700; color: #000; line-height: 18px;}
                .flag {position: absolute; left: 0px; top: 2px;}
                small {display: block; font-size: 12px; color: rgba(0,0,0,0.5); margin-top: 4px; font-weight: 400;}
                .stts {color: $c_lgrn; font-weight: 700; display: block;}
                .action {
                    display: inline-block; font-size: 14px; color: #333;@include transition(all ease-out 0.25s);
                    &:hover {@include transition(all ease-out 0.25s); text-decoration: underline;}
                    &.del {
                        margin-right: 15px;
                        &:hover{
                            color:#A3A3A3;
                        }
                    }
                    &.add {font-weight: 700; color: $c_pink; display: none;}
                }
                .see-dtl {
                    display: block; margin-top: 10px; color:$c_lgrn; padding: 5px 10px; border: 1px solid $c_lgrn; text-align: center;
                    font-weight: 600; @include borderRadius(6px); max-width: 85%; font-size: 13px;@include transition(all ease-out 0.2s);
                    &:hover {color: #fff; background: $c_lgrn; @include transition(all ease-out 0.2s);}
                }
                &:nth-child(1) {border-left: 1px solid #f2f2f2}
                &:last-child {border-right: 1px solid #f2f2f2}
                input[type="checkbox"] {
                    width: 22px; height: 22px; background: url('../images/material/ck_box.png') no-repeat top center; margin-left: 20px; cursor: pointer;
                    &:checked {background-position: bottom center;}
                }
                select {background-color: transparent !important;}
            }
            tr{
                &:nth-child(even) {
                    td {
                        background: #f2f2f2;
                        .flag {
                            &:after {border-bottom-color: #f2f2f2;}
                        }
                    }
                }
                &:last-child{
                    td {
                        .action {
                            &.add {display: inline-block;}
                        }
                        &:nth-child(1) {@include borderRadius( 0 0 0 10px); overflow: hidden;}
                        &:last-child {@include borderRadius(0 0 10px 0); overflow: hidden;}
                    }
                }
                &.title {
                    td {background: $c_yl; text-align: left;}
                }
                &.sum {
                    td {
                        background: $c_dgrey; color: #BBBDBE;
                        input {color: #BBBDBE !important;}
                    }
                }
            }
        }
        &.main-project {
            thead {
                background: $c_dgrey; color: #BBBDBE; text-transform: uppercase;
                th {
                    padding: 35px 15px 15px;font-size: 14px; font-weight: 600; text-align: left;
                        &:nth-child(1) {padding-left: 45px;}
                        &:last-child {width: 150px;}
                }
            }
            tbody {
                td {
                    padding: 20px 15px; line-height: 16px;
                }
            }
            &.auto {
                thead {
                    th {
                        &:last-child {width: auto; }
                    }
                }
            }
            &.perihal {
                thead {
                    th {
                        &:last-child {width: 300px; }
                    }
                }
            }
        }
        &.nilai {
            width: 1500px;
            thead {
                th {
                    min-width: 100px; text-align: center; vertical-align: middle; padding: 12px 20px; font-size: 14px;
                    span {display: block;}
                    &:first-child {width: 200px !important; text-align: left; padding-left: 35px;}
                }
            }
            tbody {
                td {
                    text-align: center;padding: 10px 20px;
                    select {text-align: center; display: inline-block; padding: 5px 25px 5px 10px; background-color: transparent;}
                    &:first-child {width: 200px !important; text-align: left; padding-left: 30px;}
                }
            }
        }
        &.evaluasi {
            thead {
                th {
                    text-align: center; vertical-align: middle; padding: 15px 25px;
                    &:first-child {width: 300px;text-align: left; padding-left: 40px;}
                }
            }
            tbody {
                td {
                    text-align: center;padding: 15px 25px;
                    &:first-child {text-align: left;width: 300px; padding-left: 35px;}
                }
            }
        }
        &.peralatan {
            min-width: 1200px;
            thead {
                th {
                    &:first-child {width: 300px;}
                    &:last-child {width: 200px;}
                }
            }
        }
        &.peserta {
            min-width: 2000px;
            thead {
                th {
                    &:first-child {width: 150px;}
                    &:last-child {width: 160px;}
                    &:last-child(2) {width: 300px;}
                }
            }
            tbody {
                td {line-height: 18px;}
            }
        }
        &.psikotes {
            min-width: 2300px;
        }
    }
    .scroll-tbl {overflow: auto;}
}

.pagging {
    padding: 35px 0; background: $c_dgrey; text-align: center; @include borderRadius(0 0 15px 15px); border-top: 2px solid $c_yl;
    ul {
        display: inline-block; border: 1px solid rgba(255,255,255,0.2); @include borderRadius(4px);
        li {
            display: inline-block; vertical-align: middle; 
            a,span { 
                @include transition(all ease-out 0.25s);height: 30px; width: 30px; display: block; line-height: 30px; font-size: 12px; font-weight: 600; color: rgba(255,255,255,0.2); border-right: 1px solid rgba(255,255,255,0.2); position: relative;margin: -1px -1.5px;
                &:hover, &.active {color: #fff; background: rgba(255,255,255,0.2);@include transition(all ease-out 0.25s);}
            }
            .prev,.next {
                &:after {content:""; width: 6px; height: 9px; background: url('../images/material/arr_pagging.png') no-repeat right center; background-size: 14px; display: block; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; @include transition(all ease-out 0.25s);}
                &:hover {
                    &:after {
                         @include transition(all ease-out 0.25s);background-position: left center;
                    }
                }
            }
            .next {margin-right: 0; @include borderRadius(0 3px 3px 0);}
            .prev {
                margin-left: 0;@include borderRadius(3px 0 0 3px);
                &:after {@include transform(rotate(-180deg));}
            }
            &:last-child {
                a {border-right: none;}
            }
        }  
    }
    &.white {
        background: #fff; border-top: none; padding-top: 30px; margin-bottom: 30px;
        ul {
            border-color: #d8d8d8;
            li {
                a, span {
                    color: #8b8b8b;border-color: #d8d8d8;
                    &:hover, &.active {color: #fff; background: #455057;@include transition(all ease-out 0.25s);}
                }
            }
        }
    }
}

.inner-wrap {
    padding: 65px 100px; @include borderRadius(20px);@include boxShadow(3px 3px 15px -2px rgba(0,0,0,0.05)); background: #fff;
    &.no-pt {padding-top: 0; background: #fff;}
}
.form-std {
    h2 {font-size: 24px; font-weight: 700; color: #333; margin-bottom: 25px;}
    h5 {color: #555977; font-size: 16px; font-weight: 700; margin-bottom: 12px;}
    h3 {font-size: 20px; font-weight: 700; color: #333;margin-bottom: 30px;}
    hr {
        border: none; height: 1px; background: #d8d8d8; display: block;margin: 40px 0;
        &.doted {
            border-bottom: 1px dashed #d8d8d8; background: transparent; margin: 30px 0;
        }
    }
    ol,ul {
        margin: 5px 0 10px;
        li {font-size: 16px; line-height: 24px;}
    }
    .box-grey {
        padding: 65px 100px 0; margin: -65px -100px 70px; background: $c_dgrey; @include borderRadius(20px 20px 0 0); height: 370px; @include boxSizing(border-box); top: 0px; position: relative;
        h2 {color: #fff;}
        .row {
            margin-bottom: 25px;
            label {color: #B0B4B7;}
            .lbel {font-size: 20px; color: #fff;}
        }
        &:before {
            content: ""; height: 107px; width: 39px; background: url('../images/material/bg-bx-grey.png') no-repeat; position: absolute; left: 20px; bottom: 10px;
        }
    }
    .row {
        margin-bottom: 22px;@extend .after_clear;
        label {
            font-size: 15px; font-weight: 600; color: #9C9DA5;display: block; margin-bottom: 10px;
            &.ck-label{display: none;}
        }
        @include placeholder {color: #333;}
        input[type="text"],input[type="email"],input[type="number"],input[type="password"],select,textarea {
            font-size: 14px; color: #333; padding: 13px 20px; border-color: #D8D8D8; width: 100%; position: relative;@include boxSizing(border-box); @include borderRadius(8px); background: #fff;
            &:disabled {
                background-color: #F2F2F2;
            }
        }
        textarea {resize: none; height: 100px;}
        select {
            background: #fff url('../images/material/arr-select.png') no-repeat 95%; padding-right: 40px; background-position: right 15px center;
        }
        input[type="text"].datepick {padding-right: 30px; background: #fff url('../images/material/ico-date.png') no-repeat right 12px center;}
        .input_file {
            padding: 13px 20px; border:1px solid #D8D8D8; @include boxSizing(border-box);@include borderRadius(8px); display: block; position: relative; height: 44px !important;
            input {
                width: 100%;@include boxSizing(border-box);
            }
            > span {font-size: 14px; color: #333; display: block; padding-right: 80px; overflow: hidden;text-overflow: ellipsis; white-space: nowrap;}
            &:before {
                content: "Browse"; padding: 0 15px; color: #333; font-size: 14px; display: inline-block; position: absolute; right: 15px; background: #E8E8E8; line-height: 26px; @include borderRadius(4px); height: 26px; top: 0; bottom: 0; margin: auto;
            }
            &.disabled {
                input {visibility: hidden;}
            }
        }
        .btn {cursor: pointer; display: block; border: none; width: 100%; font-weight: 700; line-height: 44px;}
        .ck.ck-editor__main>.ck-editor__editable {
            padding: 0 20px; height: 100px; font-size: 14px; color: #333; border-color: #d8d8d8; @include borderRadius(0 0 8px 8px);
            &:focus {border-color: #d8d8d8;}
            ol,ul {
                li {font-size: 14px; line-height: 20px;}
            }
            ol {list-style: decimal; padding-left: 20px;}
            ul {list-style: disc; padding-left: 20px;}
        }
        .ck.ck-editor .ck-editor__top .ck-sticky-panel .ck-toolbar {@include borderRadius(8px 8px 0 0)}
        .lbel {
            color: #333; font-size: 16px; display: block; font-weight: 600;
            &.ttd {
                width: 200px; text-align: center; margin: auto; margin-top: 70px;
                small {display: block; font-size: 14px; line-height: 18px; margin-top: 8px;}
            }
        }
        .col {
            float: left; width: 100%;
            &.col2 {
                width: 46.875%; margin-right: 6.25%;
                &:last-child {margin-right: 0;}
            }
        }
        .select2-container {
            vertical-align: bottom;
            .select2-selection--single {height: 44px; border-color: #D8D8D8;@include borderRadius(8px);}
        }
        .select2-container--default .select2-selection--single .select2-selection__arrow {
            width: 18px; height: 18px; background: url('../images/material/arr-select.png') no-repeat; top: 0; bottom: 0; margin: auto; right: 20px;
           b {display: none;} 
        }
        .select2-container--default .select2-selection--single .select2-selection__rendered {padding: 13px 20px; line-height: normal;font-family: 'Source Sans Pro'; font-size: 14px; color: #333; padding-right: 40px;}
        table {
            td {
                select {padding: 10px 35px 10px 10px; background-color: transparent !important; border: none;}
                input[type="text"],input[type="number"] {padding: 10px 2px; border: none;background-color: transparent !important;}
            }
        }
        .ntf {font-style: italic; color: #666; font-size: 13px; margin-top: 7px; display: block;}
        &.signature {
            text-align: center;
        }
    }
    .select-payment {
        .dt-select {display: none;}
        #cash {display: block;}
    }
}
.ls-program {
    margin-bottom: 30px;
    h5 {color: #555977; font-size: 16px; font-weight: 700; margin-bottom: 12px; line-height: 24px;}
    hr {
        border: none; height: 1px; background: #d8d8d8; display: block;margin: 30px 0;
        &.doted {
            border-bottom: 1px dashed #d8d8d8; background: transparent;
        }
    }
    table {
        margin-top: 0;
        thead {
            th {
                text-transform: none;
                &:last-child {width: 120px;}
            }
        }
        tbody {
            td {
                select {background-color: transparent;border: none; margin-left: -20px;}
            }
        }
    }
    .ls {
        font-size: 16px; color: #333;
        p {font-size: 16px; color: #333; line-height: 30px;}
        ol,ul {
            li {font-size: 16px; color: #333; line-height: 30px;}
        }
    }
}

.top_nav {
    padding: 40px 100px 0; margin: 0 -100px; background: $c_dgrey; @include borderRadius(15px 15px 0 0);
    ul {
        @extend .after_clear;
        li {
            display: table; padding: 0 15px; width: auto; @include boxSizing(border-box); max-width: 85px;  vertical-align: bottom;height: 60px; float: left; border-right: 1px solid #455057;
            &:first-child {padding-left:0;}
            &:last-child {padding-right:0; border-right: none;}
            a {
                color: #d2d2d2; font-size: 13px; font-weight: 600; text-align: center; display: table-cell; vertical-align: middle;@include transition(all ease-out 0.25s); position: relative; line-height: 16px;
                &:after {content:""; left: 0; right: 0; bottom: 0; height: 0; background: $c_yl;display: block; @include transition(all ease-out 0.25s); bottom: 0; top: auto; position: absolute;}
                &:hover, &.active {
                    @include transition(all ease-out 0.25s); color: $c_yl;
                    &:after {
                        @include transition(all ease-out 0.25s); height: 3px;
                    }
                }
            }
        }
    }
    .mnu-top-mob {display: none;}
}

.detail-wrp {
    position: relative; padding-top: 85px;
}
.text-content {
    h2 {font-size: 24px; font-weight: 700; color: #333; margin-bottom: 25px;}
    p {font-size: 16px; color: #333; line-height: 30px;}
    .lbel {line-height: 24px;}
    .form-std {
        .row {
            label {font-size: 14px;}
        }
    }
    ol {
        li {
            padding-left: 25px; counter-increment: count; position: relative;
            &:before {content:counter(count)")."; position: absolute; left: 0; top: 0;}
        }
    }
    ul {
        li {
            padding-left: 25px; counter-increment: list; position: relative;
            &:before {content:counter(list, lower-alpha)'.'; position: absolute; left: 0; top: 0;}
            ol {
                li {
                    counter-increment: count;
                   &:before {content:counter(count)")."; position: absolute; left: 0; top: 0;} 
                }
            }
        }
    }

}

.head-view {
    @extend .after_clear; padding-bottom: 70px; margin-bottom: 40px; border-bottom: 1px solid #d8d8d8;
    .lf {@extend .after_clear;}
    .ic {width: 96px; height: 96px;float: left; margin-top: -20px;}
    .info-view {
        margin-left: 120px;
        h2 {font-size: 40px; color: $c_black; margin-bottom: 4px;}
        h6 {font-size: 21px; color: $c_pink; margin-bottom: 20px;}

    }
}


.accordion {
    .acc {
        margin-bottom: 30px;
        .top {
            padding: 0 40px; background: #f2f2f2; @include borderRadius(8px); position: relative; cursor: pointer;
            h4 {font-size: 18px; font-weight: 700; color: #555977; padding: 17px 0;}
            &:after,&:before {content: ""; right: 20px; width: 30px; height: 30px; background: $c_dgrey; @include borderRadius(8px); display: block; position: absolute; top: 0; bottom: 0; margin: auto; @include transition(all ease-out 0.3s);}
            &:after {background: url('../images/material/acc-arr.png') no-repeat center; z-index: 3;}
            &.act {
                @include borderRadius(8px 8px 0 0);
                &:after {
                     @include transition(all ease-out 0.3s); @include transform(rotate(-180deg)); z-index: 3;
                }
            }
        }
        .content-acc {
            padding: 25px 70px; border: 1px solid #f2f2f2; border-top: none; @include borderRadius(0 0 8px 8px); margin-top: -2px; display: none;
        }
    }
}

.list-doc {
    margin: 40px 0 20px;
    &.view {margin-top: 20px;}
    .doc {
        background: $c_dgrey; @include borderRadius(8px); width: 300px; @extend .after_clear; height: 70px; position: relative; margin-bottom: 15px;
        .ic {
            background: #222527 url('../images/material/ic-doc.png') no-repeat center; width: 70px; height: 70px; display: block; float: left; @include borderRadius(8px 0 0 8px);float: left;
        }
        .desc-doc { 
            margin-left: 95px; padding: 17px 0; padding-right: 20px;
            h6 {font-size: 16px; font-weight: 700; color: #A6ABAE; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
            span {display: block; font-size: 15px; color: #E8E9EA; margin-top: 5px;}
        }
        .close {
            width: 20px; height: 20px; @include borderRadius(50%); background:#222527; position: absolute; right: -7px; top: -5px; display: block; cursor: pointer;
            &:after {
                 @include transition(all ease-out 0.25s);content: ""; width: 10px; height: 10px; background: url('../images/material/ico-x.png') no-repeat; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto;
            }
            &:hover {
                &:after {@include transition(all ease-out 0.25s); @include transform(scale(0.85));}
            }
        }
    }
}
.box-cloning {
     margin-bottom: 40px;
}

.wrp-radio {
    margin-top: 20px;
    .ck_radio {
        display: inline-block; position: relative; padding-left: 30px; margin-right: 30px;
        input {
            position: absolute; left: 0; right: 0; width: 100%; @include boxSizing(border-box); z-index: 4; cursor: pointer;
            &:checked {
                +span {
                    +i {
                        &:after {opacity: 1;}
                    }
                }
            }
        }
        span {font-size: 15px; font-weight: 600; line-height: 20px; position: relative; z-index: 2;}
        i {
            width: 20px; height: 20px;@include borderRadius(50%); @include boxSizing(border-box); border: 1px solid #d8d8d8; display: block; position: absolute; left: 0; top: 0; z-index: 2;
            &:after {content:""; left: 3px; top: 3px; right: 3px; bottom: 3px; display: block; position: absolute; background: $c_yl; @include borderRadius(50%); opacity: 0;}
        }
    }
}
.field-box {
    position: relative; padding: 45px 60px 30px; background: #F8F8F8; @include borderRadius(15px);margin-bottom: 30px;
    &:last-child{margin-bottom: 0;}
    .trigger-clone {
        position: absolute; right: 60px; top: 45px;
        a {
            width: 30px; height: 30px; display: inline-block; text-align: center; line-height: 26px; font-size: 24px; background: #AFAFAF; @include borderRadius(8px); color: #fff; vertical-align: middle; margin-left: 5px;
            &.add {font-size: 20px; line-height: 30px; background: #686868;}
            &:hover {color: $c_yl;}
        }
    }
}

.menu-sub {
    padding-bottom: 20px; margin-bottom: 40px;
    ul {
        li {
            padding: 0 20px; display: inline-block; border-left: 1px solid #d8d8d8;
            a {
                color: #333; font-size: 16px; font-weight: 700; text-transform: uppercase; display: block; position: relative; @include transition(all ease-out 0.3s); padding: 10px 0 15px;
                &:after {content:""; width: 0; height: 3px; background: $c_yl; display: block; position: absolute; left: 0; right: 0; bottom: 0; margin: auto; @include transition(all ease-out 0.3s);}
                &:hover, &.active { 
                    @include transition(all ease-out 0.3s);
                    &:after { @include transition(all ease-out 0.3s); width: 40px;}
                }
            }
            &:before {display: none;}
            &:first-child {padding-left: 0; border-left: none;}
        }
    }
}

.ui-datepicker .ui-datepicker-prev {background: url("../images/material/arr-select.png") no-repeat center; @include transform(rotate(90deg));}
.ui-datepicker .ui-datepicker-next {background: url("../images/material/arr-select.png") no-repeat center; @include transform(rotate(-90deg));}
#ui-datepicker-div {z-index: 999 !important;}

/* pop
----------------------------------------------------------------------------------------------*/

.wrap_pop {
    position: fixed; height: 100%; top: 0; right: 0; bottom: 0; left: 0; width: 100%;
    background: rgba(0,0,0,0.7); z-index: 99; display: none; overflow-y: auto;
    .pop_inner {
        position: absolute; display: table; height: 100%; width: 100%;
        .overlay_pop {position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; z-index: 7;}
        .box_pop {display: table-cell; vertical-align: middle;}
        .content_pop {
            width: 600px; margin: 5% auto; background: #f2f2f2; position: relative; z-index: 8; padding: 35px; @include boxSizing(border-box); @include borderRadius(8px);
            .close {
                width: 40px; height: 40px; background: transparent; display: inline-block; @include borderRadius(50%);
                position: absolute; top: -45px; right: 5px; z-index: 10; @include transition(all ease-out 0.25s);
                &:after,
                &:before {content:''; width: 30px; height: 2px; background: #ccc; position: absolute; display: block; top: 16px; left: 0; right: 0; margin: auto;}
                &:before {@include transform(rotate(45deg))}
                &:after {@include transform(rotate(-45deg))}
                &:hover {
                    @include transform(scale(0.8)); @include transition(all ease-out 0.25s);
                    &:after, &:before {background: #f4bd40;}
                }
            }
            .form-std {
                .row {
                    &:last-child {margin-bottom: 0;}
                }
            }
        }
    }
}
#filter {
    .pop_inner {
        .content_pop {
            width: auto; margin: 0 90px 0 390px;

        }
    }
}


.mCSB_scrollTools {opacity: 1;}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {height: 10px; background: #F4F9FC;}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {height: 8px; margin: 6px 0; background:#DCE4E8; }

.mCSB_inside > .mCSB_container {margin-right: 0;}
.mCSB_scrollTools .mCSB_dragger {opacity: 1;}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{background: #ccc;}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {width: 3px; background: #ccc !important; opacity: 1;}
.mCSB_scrollTools .mCSB_draggerRail {background: #f2f2f2; width: 3px;}

.mCSB_scrollTools.mCSB_scrollTools_vertical .mCSB_dragger.mCSB_dragger_bar {opacity: 1; background: #ccc;}


#pop-detail-step {
    .pop_inner {
        .content_pop {width: 980px;}
    }
}

/* Safari */
@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: scale(1.1); -webkit-transform: scale(1.1); -moz-transform: scale(1.1); -ms-transform: scale(1.1); -o-transform: scale(1.1); }
25% { transform: scale(0.7); -webkit-transform: scale(0.7); -moz-transform: scale(0.7); -ms-transform: scale(0.7); -o-transform: scale(0.7); }
50% { transform: scale(1.1); -webkit-transform: scale(1.1); -moz-transform: scale(1.1); -ms-transform: scale(1.1); -o-transform: scale(1.1); }
75% { transform: scale(0.7); -webkit-transform: scale(0.7); -moz-transform: scale(0.7); -ms-transform: scale(0.7); -o-transform: scale(0.7); }
100% { transform: scale(1.1); -webkit-transform: scale(1.1); -moz-transform: scale(1.1); -ms-transform: scale(1.1); -o-transform: scale(1.1); }

}

.step-list {
    @extend .flex-list; position: relative;
    .step {
        width: 150px; position: relative; padding-top: 30px;
        h3 {font-size: 17px; text-align: center; margin-bottom: 15px; height: 35px; top: 50%;}
        &.act {
            .dot-line {
                i {
                    background: #50C5B7; cursor: pointer;
                    &:before {opacity: 0.35;-webkit-animation: spin 5s linear infinite;
                        /*Safari*/animation: spin 5s linear infinite;}
                }
            } 
        }
        &.exp {
            > .dot-line {
                i {
                    background: #EE4266; cursor: pointer;
                    &:before {opacity: 0.35; background: #EE4266;-webkit-animation: none;
                        /*Safari*/animation: none;}
                }
            } 
        }
        &.expand {
            .dot-line i {@include transition(all ease-out 0.3s);}
        }
        &:first-child {
            .dot-line {
                &:before {opacity: 0;}
            }
            .drop {left: 0; @include transform(translateX(0));}
        }
        &:last-child {
            .dot-line {
                &:after {opacity: 0;}
            }
            .drop {left: -70%; @include transform(translateX(0));}
        }
        &:nth-last-child(2),
        &:last-child {
            .drop {
                &.hv-sub {
                    right: auto; @include transform(translateX(0)); margin-top: -20px; left: -136%;
                }
                .sub-step {
                    &:before {right: 0; left: auto;}
                    .step {
                        .dot-line {left: auto; right: -11px;}
                        h3 {margin-right: 30px; text-align: right;}
                        .drop {margin-left: 0; margin-right: 15px; right: 20px; left: auto;}
                        &:last-child {
                            .drop {right: 20px; left: auto;}
                        }
                    }
                }
            }
        }
        .dot-line {
            position: relative; left: 0; right: 0; display: block; margin-bottom: 20px;
            i {
                width: 18px; height: 18px; @include borderRadius(50%); background: #ccc; content: ""; position: relative; left: 0; right: 0; top: 0;margin: auto; display: block; z-index: 4; @include transition(all ease-out 0.3s);
                &:before {width: 30px; height: 30px; background: #50C5B7; opacity: 0; @include borderRadius(50%); content: ""; position: absolute; left: -6px; right: 0; top: -6px;
                    margin: auto;}
            }
            &:before,&:after {
                content: ""; height: 3px; background: #ccc; position: absolute; width: 50%; top: 0; bottom: 0; margin: auto;
            }
            &:before{
                left: 0; right: auto;
            }
            &:after {
                right: 0; left: auto;
            }
        }
        .drop {
            position: relative; width: 280px; left: 50%; @include transform(translateX(-50%)); display: none;
            &.hv-sub {
                left: 60%; @include transform(translateX(0)); margin-top: -20px;
            }
            table {
                width: 100%;padding: 20px; background: #ccc; overflow: hidden; @include borderRadius(6px);
                tr {
                    &:first-child {
                        td {padding-top: 20px;}
                    }
                    &:last-child {
                        td {padding-bottom: 20px;}
                    }
                }
                td {
                    padding: 8px 10px; font-size: 15px;
                    &:first-child {padding-left: 15px;} 
                    &:last-child {font-weight: 600; padding-left: 10px;}
                    &:nth-child(2){padding: 0;}
                }
            }
            .sub-step {
                position: relative;
                &:before {content: ""; left: -16px; width: 3px; background: #ccc; position: absolute; height: 70%;}
                .step {
                    width: 100%;
                    h3 {text-align: left; margin-left: 15px; height: auto; font-size: 16px;}
                    &:first-child {
                        padding-top: 50px;
                        .dot-line {
                            &:before {opacity: 1; height: 50%;}
                            &:after {height: 50%;}
                            i {top: 70px;}
                        }
                        &.expand {
                            padding-top: 30px;
                            .dot-line {
                                &:after {height: 100%;}
                            }
                        }
                    }
                    &:last-child {
                        .drop {
                            left: 0; right: auto;
                        }
                        .dot-line {
                            &:before {height: 70%;}
                        }
                    }
                    .dot-line {
                        position: absolute; left: -20px; height: auto; width: 18px; top: 0; bottom: 0;
                        &:before,&:after {left: 4px; right: auto;}
                        &:before {width: 3px; height: 110%; top: 0; bottom: auto;}
                        &:after {width: 3px; height: 180%; top: auto; bottom: -70px;}
                        i {
                            position: absolute; left: -2px; right: auto; top: 50px; bottom: 0; margin: auto; background: #ccc; width: 14px; height: 14px;
                            &:before {opacity: 0; top: -5px; left: -5.5111px; width: 25px; height: 25px;}
                        }
                    }
                    .drop {
                        margin-left: 15px; display: none;
                    }
                    &.act {
                        .dot-line {
                            i {
                                background: #50C5B7;
                                &:before {opacity: 0.4;}
                            }
                        }
                    }
                }
            }
        }
    }
}

.info-project {
    margin-bottom: 55px;
}
.wrap-dashboard {
    padding: 20px 0 30px; margin-bottom: 20px;@include boxShadow(0 0 15px -2px rgba(0,0,0,0.05)); @include borderRadius(0 0 20 20px); overflow: hidden;
    .ls-info {
        @extend .flex-list; justify-content: center;
        .col {
            padding: 0 45px;
            label {font-size: 14px; font-weight: 600; color: #B0B4B7; display: block; line-height: 18px;}
            h4 {font-size: 18px;color: #2B2B2B; line-height: 24px;}
            &.main {
                position: relative; border-right: 1px solid #EBEBEB; padding-left: 55px;
                &:before {content: ""; width: 45px; height: 42px; position: absolute; left: 0px; top: 0;background:url('../images/material/ico-mnu-1.png') no-repeat bottom center; background-size: 100%; }
            }
        }
    }
}
.tab-progress {
    @extend .flex-list; justify-content: space-between;
   .lf-tab {
        width: 27%; background: #3B454B;padding: 55px 20px 55px 50px; @include boxSizing(border-box); @include borderRadius(20px 0 0 20px); overflow: hidden;
        h3 {font-size: 20px; color: #fff;  font-weight: 700; margin-bottom: 32px;}
        nav {
            margin: 0 -20px 0 -50px;
            ul {
                li {
                    a {
                        padding: 10px 20px 10px 50px; display: block; color: #fff; position: relative; border-bottom: 1px solid transparent;
                        &:before {content: ""; left: 0; right: 0; background: #1F2122; display: block; position: absolute; top: 0; height: 0; @include transition(all ease-out 0.25s); z-index: 1;;}
                        label {color: #B0B4B7; display: block; margin-bottom: 5px; line-height: 16px; font-weight: 600; position: relative;z-index: 2;}
                        h5 {font-size: 18px; line-height: 24px;position: relative;z-index: 2}
                        &.active,&:hover {
                            &:before {@include transition(all ease-out 0.25s); height: 100%;}
                        }
                    }
                }
            }
        }
   } 
   .rh-tab {
       width: 70%; padding-top: 10px; padding-right: 40px; @include boxSizing(border-box);
   }
}
.wrap-item-chart {
    @extend .flex-list;
    .item-chart {
        width: calc(49%);@include boxShadow(0 0 15px -2px rgba(0,0,0,0.05)); @include borderRadius(0 0 20 20px); padding: 25px 40px; overflow: hidden;
        @include boxSizing(border-box); margin-right: 2%; margin-bottom: 20px;
        &:nth-child(2n) {margin-right: 0;}
        h4 {font-size: 18px; color: #2B2B2B; font-weight: 600; margin-bottom: 15px;}
    }
}
.highcharts-subtitle {font-size: 14px; margin-top: -10px; position: relative;}
.highcharts-a11y-proxy-button {display: none !important;}
.gm-style .gm-style-iw-c {padding: 0;background: #1F2122;}
.gm-style .gm-style-iw-t::after {background: #1F2122;}
.gm-style .gm-style-iw-d {background: #1F2122;overflow:  hidden !important;}
.gm-ui-hover-effect {
    background: url('../images/material/ico-x.png') no-repeat center !important; opacity: 1 !important; top: 4px !important; right: 4px !important;
    img {display: none !important;}
}
.map_content {
    background: #1F2122; color: #fff; padding: 20px 30px 0 20px;
    table {
        background: none; margin-top: 0;;
        td {
            background: transparent !important; border: none !important; color: #fff !important; text-align: left !important;
            padding: 6px 0 !important; min-width: 100px;
            &:last-child {text-align: right !important; color: #EE4266 !important;}
        }
    }
}

.wrap-profile {
    padding: 40px; background: #fff; @include borderRadius(10px); margin-bottom: 50px; @include boxShadow(3px 3px 15px -2px rgba(0, 0, 0, 0.05));
    width: 420px; margin: 0 auto;
    .figure-photo {
        width: 150px; height: 150px; @include borderRadius(50%); border: 6px solid #50C5B7; @include boxSizing(border-box); position: relative;
        overflow: hidden; margin: 40px auto;
        img {height: 100%; width: 100%; max-width: none; object-fit: cover; display: block; position: relative;}
    }
}

.photo-profile {
    width: 150px; position: relative; text-align: center; margin: 0 auto 40px; 
    .photo-edit {
        width: 150px; height: 150px; @include borderRadius(50%); border: 6px solid #50C5B7; @include boxSizing(border-box); position: relative;
        overflow: hidden; margin: 40px 0 15px;
        img {height: 100%; width: 100%; max-width: none; object-fit: cover; display: block; position: relative; z-index: 2;}
        input {position: absolute; z-index: -1; top: 0; bottom: 0; opacity: 0; background: none; width: auto; left: 0; right: 0; top: 0; bottom: 0;}
        .deleted {
            width: 16px; height: 16px; background: url('../images/material/icon-remove.png') no-repeat center; position: absolute; bottom: 10px; right: 0px; display: block; background-size: 100%; cursor: pointer; visibility: hidden; z-index: 4;
            left: 0; margin: 0 auto;
        }
        &.sucess {
            .deleted {visibility: visible;}
        }
    }
    .trigger-edit {
        font-size: 12px; padding: 0px 20px; line-height: 30px; @include borderRadius(5px); 
    }
}
.form-std.setting {
    width: 420px;
    > h2 {text-align: center;}
    form > h2 {text-align: center;}
}

/* Media 1366
----------------------------------------------------------------------------------------------*/

@media all and(max-width: 1400px) {


.btn  {
    line-height: 42px; font-size: 13px;
    &.plus {
        &:after {width: 26px; height: 26px; background-size: 26px;}
    }
}
.btn-ico {
    line-height: 34px;font-size: 13px; background-size: 22px !important;
}
.small-btn {font-size: 12px; line-height: 24px;}
.login {
    .wrapper {width: 1170px;}
    .txt {
        width: 570px;
        h3 {font-size: 36px; line-height: 42px;}
        p {font-size: 20px;}
    }
    .form-std {
        width: 480px; padding: 35px 40px;
    }
}
.btn-ico.export {line-height: 42px;}


header {
    padding: 30px 20px; padding-right: 20px; width: 80px;
    &.mnu-text {
        width: 260px;padding-right: 30px;
        + .middle-content {padding-left: 260px;}
        .mnu-asside {
            nav {
                ul {
                    li {
                        a {
                            i {left: 20px;}
                        }
                    }
                }
            }
        }
    }
    .mnu-asside {
        .logo {margin-bottom: 30px; margin-left: 0;}
        h5 {margin-left: 2px;}
        nav {
            ul {
                li {
                    a {
                        padding: 8px 20px 8px 50px; margin-left: -20px;
                        i {width: 24px; height: 24px; left: 28px;}
                        &:hover, &.active{
                            &:before {width: 5px;}
                        }
                    }
                }
            }
        }
    }
    .wrp-head {
        margin: 0 -30px; height: calc(100vh - 135px); position: relative; padding: 0 30px;overflow: auto; padding-left: -20px;
        .mCustomScrollBox {@include boxSizing(border-box); max-width: none; padding: 0 30px; margin: 0 -30px;}
        .mCSB_inside > .mCSB_container {@include boxSizing(border-box); max-width: none; padding: 0 30px; margin: 0 -30px;}
    }
}


.middle-content {
    padding-left: 80px;
    .main-content {
        padding: 30px 60px;
        .top-content {
            .lf {
                h2 {font-size: 28px; margin-bottom: 10px;}
                p {font-size: 14px;}
            }
        }
        &.dashboard {
            .bg-yllow {height: 538px;}
        }
    }
    table {
        tbody {
            td {
                h5 {padding-left: 25px;}
            }
        }
    }
    .src {
        input[type="text"]{
            padding: 12px 20px; padding-left: 40px; font-size: 13px;
        }
        input[type="submit"] {width: 26px; height: 26px; background-size: 26px;}
    }
    .ttgl-filt {line-height: 26px; padding: 7px 20px; padding-left: 42px; background-size: 26px;}
    
}

.inner-wrap {padding: 45px 70px;}
.field-box {padding: 35px 45px 20px;}
.top_nav {padding: 30px 70px 0; margin: 0 -70px;}
.head-view {
    .ic {width: 86px; height: 86px; background-size: 86px; margin-top: -10px;}
    .info-view {
        h2 {font-size: 36px; margin-bottom: 8px;}
        h6 {font-size: 18px;}
    }
}
.menu-sub {
    ul {
        li {
            a {font-size: 15px;}
        }
    }
}
.form-std {
    .box-grey {padding: 50px 70px 0; margin: -50px -70px 50px;}
}

#filter {
    .pop_inner {
        .content_pop {margin: 0 60px 0 320px;}
    }
}



}



@media all and (min-width: 1280px) {
    .top_nav .mnu-top-mob {display: none !important;}
}